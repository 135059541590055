import React from 'react'

export default function Callaction() {
  return (
    <>
           <section className="call-action section-padding sub-bg bg-img call_action" data-background="img/patrn.svg">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-lg-9">
                        <div className="content sm-mb30">
                            <h6 className="wow" data-splitting>Let’s Talk</h6>
                            <h2 className="wow" data-splitting>about your <br/> <b className="back-color">next project</b>.</h2>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-3 valign">
                        <a href="contact.html" className="butn dark curve wow fadeInUp" data-wow-delay=".5s"><span>Get In
                                Touch</span></a>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}
