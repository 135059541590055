import React from 'react'

export default function Contact_from() {
  return (
    <>
          <section class="contact section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form md-mb50">

                            <h4 class="fw-700 color-font mb-50">Get In Touch.</h4>

                            <form id="contact-form" method="post"
                                action="https://www.smartinnovates.com/uithemez/item/vie1/vie-light/contact.php">

                                <div class="messages"></div>

                                <div class="controls">

                                    <div class="form-group">
                                        <input id="form_name" type="text" name="name" placeholder="Name"
                                            required="required"/>
                                    </div>

                                    <div class="form-group">
                                        <input id="form_email" type="email" name="email" placeholder="Email"
                                            required="required"/>
                                    </div>

                                    <div class="form-group">
                                        <textarea id="form_message" name="message" placeholder="Message" rows="4"
                                            required="required"></textarea>
                                    </div>

                                    <button type="submit" class="butn dark"><span>Send Message</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1">
                        <div class="cont-info">
                            <h4 class="fw-700 color-font mb-50">Contact Info.</h4>
                            <h3 class="wow" data-splitting>Let's Talk.
                            </h3>
                            <div class="item mb-40">
                                <h5><a href="mailto:info@theinceptives.com">info@theinceptives.com</a></h5>
                                <h5><a href="tel:+17373897170" class="phone_c">+1(737) 389-7170</a></h5>
                            </div>
                            <h3 class="wow" data-splitting>Visit Us.
                            </h3>
                            <div class="item">
                                <h6>5101 Balcones Drive STE 100 Austin, 
                                    TX 78731 USA
                                </h6>
                            </div>
                            <div class="social mt-50">
                                <a target="_blank" href="https://www.facebook.com/inceptives/"><i
                                    class="fab fa-facebook-f"></i></a>
                            <a target="_blank" href="https://twitter.com/Inceptives1"><i
                                    class="fab fa-twitter"></i></a>
                            <a target="_blank" href="#0"><i class="fab fa-instagram"></i></a>
                            <a target="_blank" href="https://www.linkedin.com/company/theinceptives"><i
                                    class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
