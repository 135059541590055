import React from 'react'
import Hero_section from '../Components/Hero_section'
import About_company from '../Components/About_company'
import Service from '../Components/Service'
import Portfolio from '../Components/Portfolio'
import Testimonial from '../Components/Testimonial'
import Clients from '../Components/Clients'
import Callaction from '../Components/Callaction'
import Contact_form from '../Components/Contact_form'
import { motion} from 'framer-motion'
import Motion from '../Components/Wrapper/Motion'
export default function Home() {
  return (
    <>    
  <Motion>
      <Hero_section/>
      <About_company/>
      <Service/>
      <Portfolio/>
      <Testimonial/>
      <Clients/>
      <Callaction/>
      <Contact_form/>
</Motion>
   

    </> 
  )
}
