import React from 'react'

export default function Contact_form() {
  return (
    <>
       <section className="contact-sec section-padding position-re">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="sec-head  text-center">
                            <h6 className="wow fadeIn" data-wow-delay=".5s">Contact Us</h6>
                            <h3 className="wow color-font">Let’s Get in Touch And Make Magic Together.</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="form wow fadeInUp" data-wow-delay=".5s">
                            <form id="contact-form" method="post"
                                action="https://www.smartinnovates.com/uithemez/item/vie1/vie-light/contact.php">

                                <div className="messages"></div>

                                <div className="controls">

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <input id="form_name" type="text" name="name" placeholder="Name"
                                                    required="required"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <input id="form_email" type="email" name="email" placeholder="Email"
                                                    required="required"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <input id="form_name" type="text" name="name" placeholder="Name"
                                                    required="required"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea id="form_message" name="message" placeholder="Message"
                                                    rows="4" required="required"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="text-center">
                                                <a href="#0" className="butn bord curve mt-30">
                                                    <span>Send Massege</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line bottom left"></div>
        </section>
        <div class="map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.602321923473!2d0.05005911568406487!3d51.538853479640345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a64bd25b1739%3A0x1e847a19b700c83!2sOffice%20612%2C%20184%20High%20St%20N%2C%20London%20E6%202JA%2C%20UK!5e0!3m2!1sen!2s!4v1667864604019!5m2!1sen!2s"
                style={{border:'0'}} allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </>
  )
}
