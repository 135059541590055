import React from 'react'
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <>
       {/* <!-- ==================== Start Navbar ==================== --> */}

<nav className="navbar navbar-expand-lg change">
    <div className="container-fluid">

        {/* <!-- Logo --> */}
        <Link className="logo" to="/">
            <img src={require("../img/logo-light.png")} alt="logo"/>
        </Link>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="icon-bar"><i className="fas fa-bars"></i></span>
        </button>

        {/* <!-- navbar links --> */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item ">
                    <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/logo_design">Logo Design</Link>
                </li>
                <li className="nav-item ">
                    <Link className="nav-link" to="/Web_design">Web & Mobile Development</Link>
                </li>
                <li className="nav-item ">
                    <Link className="nav-link" to="/Graphic_design">Graphic & Animation</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/Seo">Seo</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/Branding">Branding</Link>
                </li>
                <li className="nav-item ">
                    <Link className="nav-link" to="/Digital_marketing">Digital Marketing</Link>
                </li>
                <li className="nav-item ">
                    <Link className="nav-link" to="/Combo">Combo packages</Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/Contact">Contact</Link>
                </li>
            </ul>
        </div>
    </div>
</nav>

{/* <!-- ==================== End Navbar ==================== --> */}
    </>
  )
}
