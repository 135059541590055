import React from 'react'

export default function Banner({text1,text2,text3,text4,image}) {
  return (
    <>
    <header className="works-header fixed-slider valign sub-bg" style={{backgroundImage:`${image}`}}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-9 static">
                    <div className="capt mt-50">
                        <div className="parlx text-center">
                            <h1 className="color-font">{text1}</h1>
                            <p className="text-white">{text2}<br/> <span className="color-font">{text3}</span></p>
                        </div>

                        <div className="bactxt  custom-font valign">
                            <span className="full-width">{text4}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </>
  )
}
