import React from 'react'

export default function Portfolio() {
  return (
    <>
      <section className="portfolio section-padding pb-70">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="sec-head  text-center">
                            <h6 className="wow fadeIn" data-wow-delay=".5s">PORTFOLIO COLISEUM</h6>
                            <h3 className="wow color-font">Our portfolio stands out <br/> amongst all other competitors.</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {/* <!-- filter links --> */}
                    <div className="filtering col-12">
                        <div className="filter nav nav-tabs" id="nav-tab" role="tablist">
                            <span className="active" id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"
                            >All</span>
                            <span id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Branding</span>
                            <span id="nav-contact-tab" data-toggle="tab" data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Website</span>
                            <span id="nav-creative-tab" data-toggle="tab" data-target="#nav-creative" type="button" role="tab" aria-controls="nav-creative" aria-selected="false">Creative</span>
                            <span id="nav-mobile-tab" data-toggle="tab" data-target="#mobile-app" type="button" role="tab" aria-controls="mobile-app" aria-selected="false">Mobile app</span>
                        </div>
                    </div>

                    {/* <!-- gallery --> */}
                    <div className="tab-content" id="nav-tabContent">
                    <div className="gallery full-width row tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                       
                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items web  wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="https://1tenmedia.com/" target="_blank" className="imago wow main_image">
                                    <img src={require("../img/portfolios/One_ten_media.webp")} className="main_image_img" alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a>Design</a>, <a>Design</a></span>
                                <h6>One Ten Media</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items web wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="https://metacos.world/" target="_blank" className="imago wow main_image">
                                    <img src={require("../img/portfolios/metacos.webp")} className="main_image_img" alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a>Design</a>, <a>Design</a></span>
                                <h6>Metacosmos Metaverse</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/GIF/03.gif")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>Through The Breaking</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/GIF/04.gif")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>See It Yourself</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items brand wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/Flyer/Caring_Tradition.jpg")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>Blast From The Past</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items brand wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/Flyer/SkinCare.jpg")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>Blast From The Past</h6>
                            </div>
                        </div>
                    </div>
                    <div className="gallery full-width row tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div>
                          {/* <!-- gallery item --> */}
                          <div className="col-md-6 items brand wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/Flyer/Caring_Tradition.jpg")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>Blast From The Past</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items brand wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/Flyer/SkinCare.jpg")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>Blast From The Past</h6>
                            </div>
                        </div>                        
                        </div>
                    </div>

                    <div className="gallery full-width row tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                       
                       {/* <!-- gallery item --> */}
                       <div className="col-md-6 items web  wow fadeInUp" data-wow-delay=".4s">
                           <div className="item-img">
                               <a href="https://1tenmedia.com/" target="_blank" className="imago wow main_image">
                                   <img src={require("../img/portfolios/One_ten_media.webp")} className="main_image_img" alt="image"/>
                                   <div className="item-img-overlay"></div>
                               </a>
                           </div>
                           <div className="cont">
                               <span className="tags"><a>Design</a>, <a>Design</a></span>
                               <h6>One Ten Media</h6>
                           </div>
                       </div>

                       {/* <!-- gallery item --> */}
                       <div className="col-md-6 items web wow fadeInUp" data-wow-delay=".4s">
                           <div className="item-img">
                               <a href="https://metacos.world/" target="_blank" className="imago wow main_image">
                                   <img src={require("../img/portfolios/metacos.webp")} className="main_image_img" alt="image"/>
                                   <div className="item-img-overlay"></div>
                               </a>
                           </div>
                           <div className="cont">
                               <span className="tags"><a>Design</a>, <a>Design</a></span>
                               <h6>Metacosmos Metaverse</h6>
                           </div>
                       </div>

        

                       {/* <!-- gallery item --> */}
                       <div className="col-md-6 items brand wow fadeInUp" data-wow-delay=".4s">
                           <div className="item-img">
                               <a href="project-details2.html" className="imago wow">
                                   <img src={require("../img/portfolios/Flyer/SkinCare.jpg")} alt="image"/>
                                   <div className="item-img-overlay"></div>
                               </a>
                           </div>
                           <div className="cont">
                               <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                               <h6>Blast From The Past</h6>
                           </div>
                       </div>
                   </div>

                   <div className="gallery full-width row tab-pane fade" id="nav-creative" role="tabpanel" aria-labelledby="nav-creative-tab">
                       
                      {/* <!-- gallery item --> */}
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/GIF/03.gif")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>Through The Breaking</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/GIF/04.gif")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a>, <a href="#0">WordPress</a></span>
                                <h6>See It Yourself</h6>
                            </div>
                        </div>
                   </div>





                   <div className="gallery full-width row tab-pane fade" id="mobile-app" role="tabpanel" aria-labelledby="nav-mobile-tab">
                       
                      {/* <!-- gallery item --> */}
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/mobile-app/Mobileapp-01.jpg")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a> <a href="#0"></a></span>
                                <h6>Through The Breaking</h6>
                            </div>
                        </div>

                        {/* <!-- gallery item --> */}
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/mobile-app/Mobileapp-02.webp")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a> <a href="#0"></a></span>
                                <h6>See It Yourself</h6>
                            </div>
                        </div>
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/mobile-app/mobileapp-03.jpg")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a> <a href="#0"></a></span>
                                <h6>See It Yourself</h6>
                            </div>
                        </div>
                        <div className="col-md-6 items  graphic wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <a href="project-details2.html" className="imago wow">
                                    <img src={require("../img/portfolios/mobile-app/Mobileapp-04.jpg")} alt="image"/>
                                    <div className="item-img-overlay"></div>
                                </a>
                            </div>
                            <div className="cont">
                                <span className="tags"><a href="#0">Design</a> <a href="#0"></a></span>
                                <h6>See It Yourself</h6>
                            </div>
                        </div>
                   </div>






                    </div>
                </div>
            </div>
        </section>  
    </>
  )
}
