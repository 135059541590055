import React from 'react'

export default function Graphic_pricing() {
  return (
    <>
    
    <section class="app-price section-padding">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-10">

                        <div class="sec-head  text-center">
                            <h6 class="wow fadeIn" data-wow-delay=".5s"> Pricing Package</h6>
                            <h3 class="wow color-font">Friendly Pricing Packages </h3>

                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-10">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home" aria-selected="true">Custom logo design</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile" aria-selected="false">Custom Video Animation</a>
                            </li>

                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="pric-tables">
                                    <div class="row justify-content-center ">

                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Revamp logo <br class="d-none d-lg-block"/>
                                                        Package</h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 19.99</h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/14k5kAbLE7IBef65kp" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">

                                                    <ul class="scrollbar_pricing">

                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>2</strong> Custom Logo Design Concepts by 2 Designers</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>2</strong> Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>48</strong> to 72 hours TAT </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Satisfaction Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Unique Design Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Money Back Guarantee* </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Startup logo <br class="d-none d-lg-block"/>
                                                        Package</h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 49.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/bIY6oEcPI1kdgnefZ4" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">

                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>4</strong> Custom Logo Design Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> By <strong>2</strong> Designers </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>48</strong> to 72 hours TAT </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Satisfaction Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Unique Design Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Money Back Guarantee* </div>
                                                        </li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Professional Logo <br class="d-none d-lg-block"/>
                                                        Package</h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 114.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/7sIeVa170d2V2wo14g" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Logo Design Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> By <strong>4</strong> Industry Based Designers </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>UNLIMITED</strong> Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>FREE</strong> MS Electronic Letterhead </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>FREE</strong> Custom Stationery Design
                                                                (Letterhead, Business Card, Envelope) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>48</strong> to <strong>72</strong> hours TAT
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>FREE</strong> File Formats (EPS, Ai, GIF,
                                                                JPEG, PSD) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Satisfaction Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Unique Design Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Money Back Guarantee </div>
                                                        </li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Identity Logo <br class="d-none d-lg-block"/>
                                                        Package</h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 164.99</h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/6oE4gw6rkgf76ME28m" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Logo Design Concepts by 8 Designers </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Icon Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Turnaround time 2-3 business days </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 1 Stationery Design Set (Business card, Letterhead,
                                                                Envelope &amp; Email Signature) </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Satisfaction Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee* </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Corporate Logo<br class="d-none d-lg-block"/>
                                                        Package</h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 214.99</h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/bIY8wM3f83slgneaET" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">

                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div><strong>UNLIMITED</strong> Logo Design Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div>By <strong>6</strong> Award Winning Designers </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div><strong>Free</strong> Icon Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div><strong>FREE</strong> Custom Stationery Design (Letterh
                                                                ead, Business Card, Envelope, Invoice)</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div>Double Side Flyer (OR) Bi-Fold Brochure </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div><strong>FREE</strong> MS Electronic Letterhead </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div>Email Signature Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div>UNLIMITED Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div>48 to 72 hours TAT </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div><strong>100%</strong> Satisfaction Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div><strong>100%</strong> Unique Design Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div><strong>100%</strong> Money Back Guarantee* </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Elite Logo
                                                        <br class="d-none d-lg-block"/>
                                                        Package
                                                    </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 414.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/8wM28oaHA2oh0og4gw" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>UNLIMITED</strong> Logo Design Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> By <strong>8</strong> Design Artist </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>UNLIMITED</strong> Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>2</strong> Stationary Design Sets </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>FREE</strong> MS Word Letterhead </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>Free</strong> Email Signature </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>3</strong> Page Custom Website </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Mobile Responsive </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>2</strong> Stock Photos </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>2</strong> Banner Designs </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> jQuery Slider </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> All Final Files Format </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Ownership Rights </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Satisfaction Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Unique Design Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Money Back Guarantee * </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Professional Illustrative
                                                        <br class="d-none d-lg-block"/>
                                                        Logo Package
                                                    </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 299.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/8wM28oaHA2oh0og4gw" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">

                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> UNLIMITED Logo Design Concepts </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> By 4 Designers </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> UNLIMITED Revisions </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 24 to 48 hours TAT </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Ownership Rights </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Satisfaction Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Unique Design Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee* </div>
                                                        </li>

                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>3D Logo
                                                        <br class="d-none d-lg-block"/>
                                                        Package
                                                    </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 399.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/7sI3csg1UbYR5IA6oS" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 3 Unique 3D Logo Concepts </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Light Effects and VFX </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Fully Rendered </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Multiple 3D Angles </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> By 3 Award Winning Designers </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 72 hours Turnaround Time </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> UNLIMITED Revisions </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Ownership Rights </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Satisfaction Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Unique Design Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Satisfaction Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee * </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Basic Illustrative
                                                        <br class="d-none d-lg-block"/>
                                                        Logo Package
                                                    </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 99.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/7sIdR65ng4wpef64gM" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> By 2 Designers </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> UNLIMITED Revisions </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 48 to 72 hours TAT </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG,
                                                                PDF </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Ownership Rights </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Satisfaction Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Unique Design Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee* </div>
                                                        </li>

                                                    </ul>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Startup Illustrative
                                                        <br class="d-none d-lg-block"/>
                                                        Logo Package
                                                    </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 199.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/bIY9AQ8zs9QJ4Ew3cJ" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> By 3 Designers </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 48 to 72 hours TAT </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> UNLIMITED Revisions </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG,
                                                                PDF) </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Ownership Rights </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Satisfaction Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Unique Design Guarantee </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee* </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="pric-tables">
                                    <div class="pric-tables">
                                        <div class="row ">
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Startup Video
                                                            <br class="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 209.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/14k00g9Dw5At1skcNb" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>

                                                    <div class="feat">

                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 30 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Unlimited Revisions </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Classic Video

                                                            <br class="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 409.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/9AQeVag1Ugf7ef6dRg" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 60 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Sample Theme </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Premium Video <br class="d-none d-lg-block"/>
                                                            Package</h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 809.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/00g14k9Dwfb33As5kL" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 90 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Sample Theme </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 6 Weeks Delivery </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Unlimited Animation
                                                            <br class="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 1409.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/4gwbIYbLEfb3b2U9B2" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>

                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 120 Second Video </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Professional Script </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Sample Theme </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Storyboard </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Voice - Over Sound Effects </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Professional 3D VFX

                                                            <br class="d-none d-lg-block"/>
                                                            Animation
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 2009.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/aEU14k7vo9QJ7QI8wZ" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 30s Duration - HD 1080 </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Edits/Revisions &amp; Concepts </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Professional Script </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Concept and Storyboards </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 3D Modeling </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Texturing </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Rigging </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Lighting </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Camera Setting </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Rendering </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Compositing and Special VFX </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Music and Foley </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Custom Setting, 2 Characters &amp; Graphics </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation Effects &amp; Visualization </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Voice Over - All accents (M/F) </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Editing and Final Output </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Elite 3D VFX
                                                            <br class="d-none d-lg-block"/>
                                                            Animation
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 2509.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/fZeeVa6rkaUN7QI3cH" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">

                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 60s Duration - HD 1080 </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Unlimited Edits/Revisions &amp; Concepts </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Concept and Storyboards </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 3D Modeling </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Texturing </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Rigging </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Lighting </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Camera Setting </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Rendering </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Compositing and Special VFX </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Music and Foley </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Custom Setting, 2 Characters &amp; Graphics </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation Effects &amp; Visualization </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice Over - All accents (M/F) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Editing and Final Output </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>


                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
